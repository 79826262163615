import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import formatISO from 'date-fns/formatISO';
import { toast } from 'react-toastify';

import { AppDispatch, RootState } from '../../redux/store';
import { freshBlue } from '../../styles/colors';
import { Dentist } from '../../types/dentist';
import { Language } from '../../types/language';
import { getAutomaticSignature } from '../../utils';

const API_BASE_URL = process.env.REACT_APP_API_BASE;

type TreatmentType =
  | 'treatment_type_ceramic_veneers_or_crowns'
  | 'treatment_type_orthodontic_treatment'
  | 'treatment_type_implants'
  | 'treatment_type_other';

interface AppSliceState {
  isLoading: boolean;
  loadingDentist: boolean;
  loadingEmail: boolean;
  error: string | null;
  customers: Customer[];
  activeCustomer: Customer | null;
  selectedCustomer: any;
  firebaseToken: string | null;
  firebaseUserid: string | null;
  dentist: Dentist | null;
  questions: Question[] | null;
  showProposalPreview: boolean;
  fieldTemplates: Template[] | null;
  proposals: Proposal[] | null;
  activeProposal: Proposal | null;
  emailNotification: EmailNotification | null;
  proposalListType: 'list' | 'gallery';
  languages: Language[];
  estimated_price: number | null;
  treatment_type_ceramic_veneers_or_crowns: boolean;
  treatment_type_orthodontic_treatment: boolean;
  treatment_type_implants: boolean;
  treatment_type_other: boolean;
  automaticSignature: string;
  guideUrl: string;
}

const initialState: AppSliceState = {
  error: null,
  isLoading: false,
  loadingDentist: true,
  loadingEmail: false,
  customers: [],
  activeCustomer: null,
  selectedCustomer: null,
  firebaseToken: null,
  firebaseUserid: null,
  dentist: null,
  questions: null,
  showProposalPreview: false,
  fieldTemplates: null,
  proposals: null,
  activeProposal: null,
  emailNotification: null,
  proposalListType: 'list',
  languages: [],
  estimated_price: null,
  treatment_type_ceramic_veneers_or_crowns: false,
  treatment_type_orthodontic_treatment: false,
  treatment_type_implants: false,
  treatment_type_other: false,
  automaticSignature: 'checked',
  guideUrl: '',
};

const slice = createSlice({
  name: 'proposals',
  initialState: initialState,
  reducers: {
    getRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getRequestDentist: (state) => {
      state.loadingDentist = true;
    },
    postRequest: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    getRequestFailure: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getCurrentUserDentistFailure: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.loadingDentist = false;
    },
    postRequestFailure: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    patchCustomerSuccess: (state, action: any) => {
      state.isLoading = false;
      const index = state.customers?.findIndex((customer) => customer.url === action.payload.url);
      state.customers[index] = action.payload;
    },
    getCustomersSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.error = null;
      state.isLoading = false;
      state.customers = action.payload;
    },
    getLanguagesSuccess: (state, action: any) => {
      state.error = null;
      state.isLoading = false;
      state.languages = action.payload.map((languageObject: Language) => languageObject);
    },
    getLanguagesFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    getQuestionsSuccess: (state, action: PayloadAction<Question[]>) => {
      state.error = null;
      state.isLoading = false;
      state.questions = action.payload;
    },
    getCurrentUserDentistSuccess: (state, action: PayloadAction<Dentist>) => {
      state.error = null;
      state.loadingDentist = false;
      state.dentist = action.payload;
    },
    deleteDentistSuccess: (state) => {
      state.dentist = null;
      state.loadingDentist = false;
    },
    setLoadingEmail: (state, action) => {
      state.loadingEmail = action.payload;
    },
    setEstimatedPrice: (state, action: PayloadAction<number | null>) => {
      state.estimated_price = action.payload;
    },
    setTreatmentCheckbox: (state, action: PayloadAction<{ type: TreatmentType; value: boolean }>) => {
      state[action.payload.type] = action.payload.value;
    },
    toggleAutomaticSignature: (state) => {
      if (state.automaticSignature === 'checked') {
        state.automaticSignature = 'unchecked';
      } else {
        state.automaticSignature = 'checked';
      }
    },
    patchDentistSuccess: (state, action: PayloadAction<Dentist>) => {
      state.error = null;
      state.loadingDentist = false;
      state.isLoading = false;
      state.dentist = action.payload;
    },
    getTemplateSuccess: (state, action: PayloadAction<Template[]>) => {
      state.fieldTemplates = action.payload;
      state.error = null;
      state.isLoading = false;
    },
    getGuideSuccess: (state, action: PayloadAction<any>) => {
      if (action.payload.length > 0) {
        state.guideUrl = action.payload[0].pdf;
      } else {
        state.guideUrl = '';
      }
    },
    getProposalsSuccess: (state, action: PayloadAction<Proposal[]>) => {
      state.error = null;
      state.isLoading = false;
      state.proposals = action.payload;
    },
    postTemplateSuccess: (state, action: PayloadAction<Template>) => {
      let oldIndex = -1;
      if (state.fieldTemplates) {
        oldIndex = state.fieldTemplates.findIndex(
          (t: Template) => t.field === action.payload.field && t.name === action.payload.name,
        );
        if (oldIndex >= 0) {
          state.fieldTemplates[oldIndex] = action.payload;
        }
      }
      state.error = null;
      state.isLoading = false;
      toast.success('Template text saved.');
    },
    postProposalSuccess: (state, action: PayloadAction<Proposal>) => {
      state.error = null;
      state.isLoading = false;
      state.activeProposal = action.payload;

      if (state.proposals === null) {
        state.proposals = [action.payload];
      } else {
        let updated = false;
        // If proposal exists, update it.
        state.proposals.forEach((proposal, proposalIndex) => {
          if (proposal.url === action.payload.url) {
            (state.proposals as any)[proposalIndex] = action.payload;
            updated = true;
          }
        });

        if (!updated) {
          state.proposals.push(action.payload);
        }
      }
    },
    deleteProposalSuccess: (state, action) => {
      state.error = null;
      state.isLoading = false;
      state.activeProposal = null;
      state.proposals = state.proposals && state.proposals.filter((p) => p.url !== action.payload);
      toast.success('Proposal deleted.');
    },
    setEmailNotification: (state, action: PayloadAction<EmailNotification | null>) => {
      state.emailNotification = action.payload;
      state.loadingEmail = false;
    },
    setProposalSentDate: (state, action: PayloadAction<Proposal>) => {
      let index = -1;
      let customerIndex = -1;
      if (state.proposals) {
        index = state.proposals.findIndex((p: Proposal) => p.url === action.payload.url);
        if (index >= 0) {
          state.proposals[index].date_sent = new Date().toISOString();
        }
      }
      if (state.customers) {
        customerIndex = state.customers.findIndex((c: Customer) => c.url === action.payload.customer);
        if (customerIndex >= 0) {
          state.customers[customerIndex].proposal_date_sent = new Date().toISOString();
        }
      }
    },
    setFirebaseToken: (state, action: PayloadAction<string | null>) => {
      state.firebaseToken = action.payload;
    },
    setFirebaseUserid: (state, action: PayloadAction<string | null>) => {
      state.firebaseUserid = action.payload;
    },
    setDentist: (state, action: PayloadAction<Dentist | null>) => {
      state.dentist = action.payload;
    },
    setActiveCustomer: (state, action: PayloadAction<Customer | null>) => {
      state.activeCustomer = action.payload;
    },
    setSelectedCustomer: (state, action: PayloadAction<any>) => {
      state.selectedCustomer = action.payload;
    },
    setShowProposalPreview: (state, action: PayloadAction<boolean>) => {
      state.showProposalPreview = action.payload;
    },
    postCustomerSetSeenSuccess: (state, action: PayloadAction<Customer>) => {
      state.isLoading = false;
      state.error = null;
      let index = -1;
      if (state.customers) {
        index = state.customers.findIndex((c: Customer) => c.url === action.payload.url);
        if (index >= 0) {
          state.customers[index].last_viewed = new Date().toISOString();
          state.customers[index].seen_notification_sent = true;
        }
      }
    },
    deleteCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.isLoading = false;
      state.error = null;
      let index = -1;
      if (state.customers) {
        index = state.customers.findIndex((c: Customer) => c.url === action.payload.url);
        if (index >= 0) {
          state.customers[index].archived_at = new Date().toISOString();
          state.customers[index].archived = true;
        }
      }
    },
    undeleteCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.isLoading = false;
      state.error = null;
      let index = -1;
      if (state.customers) {
        index = state.customers.findIndex((c: Customer) => c.url === action.payload.url);
        if (index >= 0) {
          state.customers[index].archived_at = null;
          state.customers[index].archived = false;
        }
      }
    },
    setProposalToActive: (state, action: PayloadAction<Proposal>) => {
      state.activeProposal = action.payload;
    },
    resetProposalState: () => initialState,
    setProposalListType: (state, action: PayloadAction<'list' | 'gallery'>) => {
      state.proposalListType = action.payload;
    },
    hardDeleteCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.isLoading = false;
      state.error = null;
      let index = -1;
      if (state.customers) {
        index = state.customers.findIndex((c: Customer) => c.url === action.payload.url);
        if (index >= 0) {
          // remove element state.customers[index]
          state.customers.splice(index, 1);
        }
      }
    },
  },
});

export const getGuide: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(getRequest());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/guide/`, { headers })
    .then((response) => response.json())
    .then((data) => {
      // Data expected to be [{"pdf": ..., "last_modified": ...}, ...] usually with only one object
      dispatch(getGuideSuccess(data));
    })
    .catch(() => {
      dispatch(getRequestFailure('Error fetching fields'));
    });
};

export const getCustomersAndQuestions: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(getRequest());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/customer/`, { headers })
    .then((response) => response.json())
    .then((data) => {
      dispatch(getCustomersSuccess(data));
      dispatch(getQuestions() as any);
    })
    .catch(() => {
      dispatch(getRequestFailure('Error fetching fields'));
    });
};

// Get the dentist that is linked to current user if there is any, otherwise complete registration.
export const getCurrentUserDentist: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(getRequestDentist());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/dentist/`, { headers })
    .then((response) => response.json())
    .then((data) => {
      let dentist = data.length > 0 ? data[0] : null;
      dispatch(getCurrentUserDentistSuccess(dentist));
    })
    .catch(() => {
      dispatch(getCurrentUserDentistFailure('Error fetching dentist data for logged in user.'));
      console.error('Error fetching user dentist');
    });
};

export const getLanguages: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }

  dispatch(getRequest());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/language/`, { headers })
    .then((response) => response.json())
    .then((languages) => {
      dispatch(getLanguagesSuccess(languages));
    })
    .catch(() => {
      dispatch(getLanguagesFailure('Error fetching languages.'));
    });
};

export const deleteCurrentUserDentist: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(postRequest());

  return fetch(`${API_BASE_URL}/api/dentist/${state.proposals.dentist?.id}/`, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${state.proposals.firebaseToken}`,
    },
    body: JSON.stringify({}),
  })
    .then((response) => {
      if (!response.ok) {
        dispatch(postRequestFailure('Failed to delete dentist'));
        throw new Error('Network response was not ok');
      }
      dispatch(deleteDentistSuccess());
    })
    .catch((error) => {
      dispatch(postRequestFailure('Failed to delete dentist'));
      console.error('Error:', error);
    });
};

// Update dentist information.
export const patchDentist: any = (requestBody: any) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();

  // Token and dentist url needs to be in store.
  if (!state.proposals.firebaseToken || !state.proposals.dentist?.url) {
    return;
  }

  dispatch(getRequest());
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(state.proposals.dentist?.url as string, { method: 'PATCH', headers, body: JSON.stringify(requestBody) })
    .then((response) => {
      return response.json();
    })
    .then((response) => {
      dispatch(patchDentistSuccess(response));
      toast.success('Profile information updated successfully.');
    })
    .catch(() => {
      dispatch(getRequestFailure('Error updating dentist.'));
      toast.error('Error updating information.');
    });
};

// Update customer
export const markCustomerDone: any =
  (customerUrl: string, timestamp: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    // Token and dentist url needs to be in store.
    if (!state.proposals.firebaseToken || !state.proposals.dentist?.url) {
      return;
    }

    dispatch(postRequest());
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${state.proposals.firebaseToken}`,
    };

    return fetch(customerUrl, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ treated: true, treated_date: timestamp }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        // dispatch(postRequestSuccess(response));
        dispatch(patchCustomerSuccess(response));
        // toast.success('Customer marked as treated.');
      })
      .catch(() => {
        dispatch(postRequestFailure('Error updating dentist.'));
        toast.error('Error updating information.');
      });
  };

// Update customer
export const markCustomerUndone: any =
  (customerUrl: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();

    // Token and dentist url needs to be in store.
    if (!state.proposals.firebaseToken || !state.proposals.dentist?.url) {
      return;
    }

    dispatch(postRequest());
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Token ${state.proposals.firebaseToken}`,
    };

    return fetch(customerUrl, {
      method: 'PATCH',
      headers,
      body: JSON.stringify({ treated: false, treated_date: formatISO(new Date()) }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        // dispatch(postRequestSuccess(response));
        dispatch(patchCustomerSuccess(response));
        // toast.success('Customer marked as not treated.');
      })
      .catch(() => {
        dispatch(postRequestFailure('Error updating dentist.'));
        toast.error('Error updating information.');
      });
  };

export const getQuestions = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(getRequest());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/question/`, { headers })
    .then((response) => response.json())
    .then((data) => {
      dispatch(getQuestionsSuccess(data));
    })
    .catch(() => {
      dispatch(getRequestFailure('Error fetching questions'));
    });
};

export const setCustomerToActive: any =
  (customer: Customer) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setActiveCustomer(customer));

    const state = getState();
    if (!state.proposals.firebaseToken) {
      return;
    }
    dispatch(postRequest());

    return fetch(`${customer.url}set_seen/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
      body: JSON.stringify({ seen_notification_sent: customer.seen_notification_sent }),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(postRequestFailure('Failed to post customer set seen'));
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((respData) => {
        if (respData?.email_was_sent) {
          // Try to send email only in the first time profile was opened
          if (respData?.email_sent_ok) {
            toast.success('Notification email sent to client');
          } else {
            toast.error('Error sending notification email to client');
          }
        }
        dispatch(postCustomerSetSeenSuccess(customer));
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to post customer set seen'));
        console.error('Error:', error);
        toast.error('Error to activate the client');
      });
  };

export const setCustomerToArchive: any =
  (customer: Customer) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.proposals.firebaseToken) {
      return;
    }
    dispatch(postRequest());

    return fetch(`${customer.url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(postRequestFailure('Failed to post customer set seen'));
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then(() => {
        dispatch(deleteCustomerSuccess(customer));
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to post customer set seen'));
        console.error('Error:', error);
        toast.error('Error to archive the client');
      });
  };

export const unsetCustomerToArchive: any =
  (customer: Customer) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.proposals.firebaseToken) {
      return;
    }
    dispatch(postRequest());

    return fetch(`${customer.url}undelete/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(postRequestFailure('Failed to post customer set seen'));
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then(() => {
        dispatch(undeleteCustomerSuccess(customer));
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to post customer set seen'));
        console.error('Error:', error);
        toast.error('Error to unarchive the client');
      });
  };

export const hardDeleteCustomer: any =
  (customer: Customer) => async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    if (!state.proposals.firebaseToken) {
      return;
    }
    dispatch(postRequest());

    return fetch(`${customer.url}hard_delete/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
      body: JSON.stringify({}),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(postRequestFailure('Failed to hard delete customer'));
          throw new Error('Network response was not ok');
        }
        return response;
      })
      .then(() => {
        dispatch(hardDeleteCustomerSuccess(customer));
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to hard delete customer'));
        console.error('Error:', error);
        toast.error('Customer deletion error');
      });
  };

export const getTemplates: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(getRequest());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/template/`, { headers })
    .then((response) => response.json())
    .then((data) => {
      dispatch(getTemplateSuccess(data));
    })
    .catch(() => {
      dispatch(getRequestFailure('Error fetching template'));
    });
};

export const saveTemplate: any =
  (field: string, name: string, text: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(postRequest());
    const state = getState();
    if (!state.proposals.firebaseToken) {
      return;
    }
    const data: PostDataForTemplate = {
      field: field,
      name: name,
      text: text,
    };
    let oldTemplate: Template | null = null;
    if (state.proposals.fieldTemplates) {
      const templates = state.proposals.fieldTemplates.filter((t: Template) => t.field === field && t.name === name);
      if (templates && templates.length) {
        oldTemplate = templates[0];
      }
    }
    if (oldTemplate) {
      return fetch(oldTemplate.url, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${state.proposals.firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            dispatch(postRequestFailure('Failed to post template'));
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          dispatch(postTemplateSuccess(data));
        })
        .catch((error) => {
          dispatch(postRequestFailure('Failed to post template'));
          console.error('Template Save Error:', error);
          toast.error('Save error occurred');
        });
    } else {
      return fetch(`${API_BASE_URL}/api/template/`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${state.proposals.firebaseToken}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (!response.ok) {
            dispatch(postRequestFailure('Failed to post template'));
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then((data) => {
          dispatch(postTemplateSuccess(data));
        })
        .catch((error) => {
          dispatch(postRequestFailure('Failed to post template'));
          console.error('Error:', error);
          toast.error('Save error occurred');
        });
    }
  };

export const deleteProposal: any =
  (proposalUrl: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(postRequest());
    const state = getState();
    if (!state.proposals.firebaseToken || !state.proposals.selectedCustomer) {
      return;
    }

    return fetch(proposalUrl, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
    })
      .then(() => {
        dispatch(deleteProposalSuccess(proposalUrl));
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to delete proposal'));
        console.error('Error:', error);
        toast.error('Error to delete the proposal');
      });
  };

export const postProposal: any =
  (treatmentProposal: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(postRequest());
    const state = getState();
    if (!state.proposals.firebaseToken || !state.proposals.selectedCustomer) {
      return;
    }

    const estimated_price = state.proposals.estimated_price;
    const treatment_type_ceramic_veneers_or_crowns = state.proposals.treatment_type_ceramic_veneers_or_crowns;
    const treatment_type_orthodontic_treatment = state.proposals.treatment_type_orthodontic_treatment;
    const treatment_type_implants = state.proposals.treatment_type_implants;
    const treatment_type_other = state.proposals.treatment_type_other;

    const data: ProposalPostData = {
      customer: state.proposals.selectedCustomer.url,
      treatment: treatmentProposal,
      treatment_type_ceramic_veneers_or_crowns,
      treatment_type_orthodontic_treatment,
      treatment_type_implants,
      treatment_type_other,
    };

    // Replace empty paragraphs with br's.
    if (data.treatment) {
      const regex = /<p><\/p>/g;
      data.treatment = data.treatment.replace(regex, '<br />');
    }

    if (estimated_price) {
      data.estimated_price = estimated_price;
    }

    return fetch(`${API_BASE_URL}/api/proposal/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
      body: JSON.stringify(data),
    })
      .then(async (response) => {
        if (!response.ok) {
          dispatch(postRequestFailure('Failed to post template'));
          throw new Error('Error posting template.');
        }
        return response.json();
      })
      .then((data) => {
        toast.success('Proposal created.');
        dispatch(postProposalSuccess(data));
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to post template'));
        console.error('Error:', error);
        toast.error('Error to save the proposal');
      });
  };

export const patchProposal: any =
  (proposalData: ProposalPostData, url: string, sendEmailAfter?: boolean) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(postRequest());
    const state = getState();
    if (!state.proposals.firebaseToken) {
      return;
    }

    const newProposalData = { ...proposalData };

    // Append dentist contact information to the email body, if checkbox is selected.
    if (newProposalData.email_body && state.proposals.automaticSignature === 'checked') {
      newProposalData.email_body += getAutomaticSignature(state.proposals.dentist as Dentist);
    }

    return fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${state.proposals.firebaseToken}`,
      },
      body: JSON.stringify(newProposalData),
    })
      .then((response) => {
        if (!response.ok) {
          dispatch(postRequestFailure('Failed to post template'));
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        dispatch(postProposalSuccess(data));
        if (sendEmailAfter) {
          dispatch(sendEmail(data) as any);
        }
      })
      .catch((error) => {
        dispatch(postRequestFailure('Failed to post template'));
        console.error('Error:', error);
        toast.error('Error to save the proposal');
      });
  };

export const sendEmail: any = (proposal: Proposal) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }

  dispatch(setLoadingEmail(true));

  return fetch(`${proposal.url}send_email/`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${state.proposals.firebaseToken}`,
    },
    body: JSON.stringify({}),
  })
    .then((response) => {
      if (!response.ok) {
        toast.error('Error sending email.');
        dispatch(
          setEmailNotification({
            text: 'Something went wrong',
            type: 'fail',
          }),
        );
        throw new Error('Network response was not ok');
      }
      return response;
    })
    .then(() => {
      toast.success('Email sent.');
      dispatch(
        setEmailNotification({
          text: 'Offer sent',
          type: 'success',
        }),
      );
      dispatch(setProposalSentDate(proposal));
    })
    .catch((error) => {
      dispatch(
        setEmailNotification({
          text: 'Something went wrong',
          type: 'fail',
        }),
      );
      console.error('Error:', error);
    });
};

export const getProposals: any = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (!state.proposals.firebaseToken) {
    return;
  }
  dispatch(getRequest());

  const headers = {
    Authorization: `Token ${state.proposals.firebaseToken}`,
  };

  return fetch(`${API_BASE_URL}/api/proposal/`, { headers })
    .then((response) => response.json())
    .then((data) => {
      dispatch(getProposalsSuccess(data));
    })
    .catch(() => {
      dispatch(getRequestFailure('Error fetching questions'));
    });
};

// Selectors related to customer / proposal list view.
export const selectCustomers = (state: RootState) => state.proposals.customers;

// Selector for inbox customers and number how many customers there are.
export const selectCustomersInbox = (state: RootState) =>
  state.proposals.customers?.filter((a: Customer) => a.proposal_date_sent === null && a.archived_at === null);
export const selectCustomersInboxNumber = (state: RootState) =>
  selectCustomersInbox(state) ? selectCustomersInbox(state)?.length : (0 as any);

// Selector for sent customers and number how many customers there are.
export const selectCustomersSent = (state: RootState) =>
  state.proposals.customers?.filter((a: Customer) => a.proposal_date_sent !== null && !a.archived_at);
export const selectCustomersSentNumber = (state: RootState) =>
  selectCustomersSent(state) ? selectCustomersSent(state)?.length : (0 as any);

// Selector for archived customers and number how many customers there are.
export const selectCustomersArchived = (state: RootState) =>
  state.proposals.customers?.filter((c: Customer) => c.archived_at !== null);
export const selectCustomersArchivedNumber = (state: RootState) =>
  selectCustomersArchived(state) ? selectCustomersArchived(state)?.length : (0 as any);

export const selectLoading = (state: RootState) => state.proposals.isLoading;
export const selectLoadingDentist = (state: RootState) => state.proposals.loadingDentist;
export const selectActiveCustomer = (state: RootState) => state.proposals.activeCustomer;

export const selectSelectedCustomer = (state: RootState) => {
  const customers = state.proposals.customers;
  const selectedCustomer = state.proposals.selectedCustomer;
  return customers?.filter((customer) => customer?.url === selectedCustomer?.url)[0] as Customer;
};

export const selectFirebaseUserid = (state: RootState) => state.proposals.firebaseUserid;
export const selectQuestions = (state: RootState) => state.proposals.questions;
export const selectShowProposalPreview = (state: RootState) => state.proposals.showProposalPreview;
export const selectFieldTemplates = (state: RootState) => state.proposals.fieldTemplates;
export const selectActiveProposal = (state: RootState) => state.proposals.activeProposal;

export const selectProposals = (state: RootState) => state.proposals.proposals;
export const selectDentist = (state: RootState) => state.proposals.dentist;
export const selectProposalsForSelectedCustomer = (state: RootState) => {
  const selectedCustomer = state.proposals.selectedCustomer;
  const existingProposal = state.proposals.proposals?.filter((p) => p.customer === selectedCustomer.url);
  return existingProposal ? existingProposal[0] : null;
};

export const selectEmailNotification = (state: RootState) => state.proposals.emailNotification;
export const selectUserNeedsToRegister = (state: RootState) => !state.proposals.dentist;
export const selectFirebaseToken = (state: RootState) => state.proposals.firebaseToken;

export const seletctProposalListType = (state: RootState) => state.proposals.proposalListType;
export const selectLoadingEmail = (state: RootState) => state.proposals.loadingEmail;

export const selectSelectedCustomerProposal = (state: RootState) => {
  const proposals: any = state.proposals.proposals;
  const selectedCustomer = state.proposals.selectedCustomer;
  return proposals.filter((proposal: Proposal) => proposal.customer === selectedCustomer?.url)[0];
};

// Statistics selectors.
export const selectStatisticsTreated = (state: RootState) => {
  const treatedValues = state.proposals.customers.map((customer) => customer.treated);
  return treatedValues.filter((value) => value === true).length;
};
export const selectStatisticsNontreated = (state: RootState) => {
  const nontreatedValues = state.proposals.customers.map((customer) => !customer.treated);
  return nontreatedValues.filter((value) => value === true).length;
};
export const selectStatisticsProposalsSentCount = (state: RootState) => {
  const customersProposalSent = state.proposals.customers.filter((customer) => customer.proposal_date_sent !== null);
  return customersProposalSent ? customersProposalSent.length : 0;
};
export const selectStatisticsTreatedAndNontreatedOfProposalsSent = (state: RootState) => {
  const customersProposalSent = state.proposals.customers.filter((customer) => customer.proposal_date_sent !== null);
  const stats = {
    treated: customersProposalSent.filter((customer) => customer.treated).length,
    nontreated: customersProposalSent.filter((customer) => !customer.treated).length,
  };
  return stats;
};
export const selectStatisticsGenderMales = (state: RootState) => {
  const maleValues = state.proposals.customers.map((customer) => customer.gender);
  return maleValues.filter((value) => value === 'm').length;
};
export const selectStatisticsGenderFemales = (state: RootState) => {
  const femaleValues = state.proposals.customers.map((customer) => customer.gender);
  return femaleValues.filter((value) => value === 'f').length;
};
export const selectStatisticsGenderNonbinary = (state: RootState) => {
  const nonbinaryValues = state.proposals.customers.map((customer) => customer.gender);
  return nonbinaryValues.filter((value) => value === 'nb').length;
};
export const selectStatisticsDates = (state: RootState) => {
  const dates = state.proposals.customers.map((customer) => format(new Date(customer.date), 'yyyy-MM-dd'));
  let datesObject: { [key: string]: any } = {};
  dates.sort();
  dates.forEach((date) => {
    datesObject[date] = {
      ...datesObject[date],
      value: date,
      count: datesObject[date]?.count ? datesObject[date].count + 1 : 1,
    };
  });

  return {
    x: Object.keys(datesObject).map((date) => datesObject[date].value),
    y: Object.keys(datesObject).map((date) => datesObject[date].count),
  };
};
export const selectStatisticsDatesProposals = (state: RootState) => {
  const dates = state.proposals.customers
    .filter((customer) => customer.proposal_date_sent)
    .map((customer) => format(new Date(customer.proposal_date_sent as string), 'yyyy-MM-dd'));
  let datesObject: { [key: string]: any } = {};
  dates.sort();
  dates.forEach((date) => {
    datesObject[date] = {
      ...datesObject[date],
      value: date,
      count: datesObject[date]?.count ? datesObject[date].count + 1 : 1,
    };
  });

  return {
    x: Object.keys(datesObject).map((date) => datesObject[date].value),
    y: Object.keys(datesObject).map((date) => datesObject[date].count),
  };
};
export const selectStatisticsBirthYears = (state: RootState) => {
  return state.proposals.customers.map((customer) => customer.birth_year);
};
export const selectStatisticsAges = (state: RootState) => {
  const birthYears = selectStatisticsBirthYears(state);
  const years = birthYears.map((year: any) => {
    return new Date().getFullYear() - year;
  });

  let ages = [0, 0, 0, 0];
  const labels = ['-17', '18-29', '30-49', '50-'];
  years.forEach((age) => {
    if (age <= 17) {
      ages[0]++;
    } else if (age <= 29) {
      ages[1]++;
    } else if (age <= 49) {
      ages[2]++;
    } else {
      ages[3]++;
    }
  });

  return {
    x: labels,
    y: ages,
  };
};
export const selectStatisticsAgesGroupByGender = (state: RootState) => {
  const labels_xaxis = ['-17', '18-29', '30-49', '50-'];
  const labels_gender = ['f', 'm', 'nb'];

  let agesByGender = [];

  for (const gender of labels_gender) {
    const customersByGender = state.proposals.customers.filter((customer) => customer.gender === gender);
    const customersByGenderWithBirthYear = customersByGender.filter((customer) => customer.birth_year);

    const currYear = new Date().getFullYear();
    let countByAgeGroup = [0, 0, 0, 0];

    customersByGenderWithBirthYear.forEach((customer) => {
      if (customer.birth_year) {
        const customerAge = currYear - customer.birth_year;
        if (customerAge <= 17) {
          countByAgeGroup[0]++;
        } else if (customerAge <= 29) {
          countByAgeGroup[1]++;
        } else if (customerAge <= 49) {
          countByAgeGroup[2]++;
        } else {
          countByAgeGroup[3]++;
        }
      }
    });

    let genderName = 'Female';
    let genderColor = 'rgb(255,178,102)';

    if (gender === 'm') {
      genderName = 'Male';
      genderColor = 'rgb(102,178,255)';
    } else if (gender === 'nb') {
      genderName = 'Non-binary';
      genderColor = 'rgb(102,255,102)';
    }

    agesByGender.push({ ageLabels: labels_xaxis, ageCounts: countByAgeGroup, gender: genderName, color: genderColor });
  }

  return agesByGender;
};

export const selectStatisticsProposalSentGenderStackedByTreated = (state: RootState) => {
  const labels_xaxis = ['Female', 'Male', 'Non-binary'];
  const labels_gender = ['f', 'm', 'nb'];

  let treatedByGender = [];
  let nontreatedByGender = [];

  const customersProposalSent = state.proposals.customers.filter((customer) => customer.proposal_date_sent !== null);

  for (const gender of labels_gender) {
    const targetCustomers = customersProposalSent.filter((customer) => customer.gender === gender);
    const targetCustomersCount = targetCustomers.length;
    const targetCustomersTreatedCount = targetCustomers.filter((customer) => customer.treated).length;

    nontreatedByGender.push(targetCustomersCount - targetCustomersTreatedCount);
    treatedByGender.push(targetCustomersTreatedCount);
  }

  let statsResult = [];
  statsResult.push({ genderLabels: labels_xaxis, values: nontreatedByGender, name: 'Non-treated', color: '#FF637F' });
  statsResult.push({ genderLabels: labels_xaxis, values: treatedByGender, name: 'Treated', color: freshBlue });

  return statsResult;
};

// Languages.
export const selectLanguagesForDropdown = (state: RootState) => {
  return state.proposals.languages;
};
export const selectLanguagesInUse = (state: RootState) => {
  return state.proposals.dentist?.extra_languages;
};
export const selectDefaultLanguage = (state: RootState) => {
  return state.proposals.dentist?.customer_default_language;
};

export const selectEstimatedPrice = (state: RootState) => {
  return state.proposals.estimated_price;
};
export const selectAutomaticSignature = (state: RootState) => {
  return state.proposals.automaticSignature;
};
export const selectGuideUrl = (state: RootState) => {
  return state.proposals.guideUrl;
};

export const {
  getRequest,
  getRequestDentist,
  postRequest,
  getRequestFailure,
  getCurrentUserDentistFailure,
  postRequestFailure,
  getCustomersSuccess,
  getTemplateSuccess,
  setFirebaseToken,
  setFirebaseUserid,
  setActiveCustomer,
  setSelectedCustomer,
  getQuestionsSuccess,
  setShowProposalPreview,
  postTemplateSuccess,
  postProposalSuccess,
  deleteProposalSuccess,
  getProposalsSuccess,
  setEmailNotification,
  setProposalSentDate,
  postCustomerSetSeenSuccess,
  setProposalToActive,
  resetProposalState,
  deleteCustomerSuccess,
  undeleteCustomerSuccess,
  getCurrentUserDentistSuccess,
  setDentist,
  patchDentistSuccess,
  setProposalListType,
  deleteDentistSuccess,
  setLoadingEmail,
  patchCustomerSuccess,
  getLanguagesSuccess,
  getLanguagesFailure,
  setEstimatedPrice,
  setTreatmentCheckbox,
  toggleAutomaticSignature,
  getGuideSuccess,
  hardDeleteCustomerSuccess,
} = slice.actions;

export default slice.reducer;

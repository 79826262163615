import * as React from 'react';

interface ViewportProps {
  width: number;
}

export const ViewportContext = React.createContext({} as ViewportProps);

export const ViewportProvider: React.FunctionComponent<any> = ({ children }) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return <ViewportContext.Provider value={{ width }}>{children}</ViewportContext.Provider>;
};

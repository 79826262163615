import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';
import './index.css';
// import { createRoot } from 'react-dom/client';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';

// TEMPORARILY USE OLD STYLE OF RENDERING SINCE RICH TEXT EDITOR (PROPOSAL EDITOR) DOES NOT WORK USING REACT18 STYLE (BELOW).
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// THIS CODE SHOULD BE USED AFTER DRAFT-JS WYSIWYG EDITOR SUPPORTS REACT18 PROPERLY.
// FONT SIZE/ FONT STYLE DROPDOWNS DOES NOT WORK WITH THIS CODE
// https://github.com/jpuri/react-draft-wysiwyg/issues/1270

// const rootElement = document.getElementById('root');
// const root = createRoot(rootElement as Element);
/* root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

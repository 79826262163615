import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { auth } from '../../config/firebase';

interface IAuthRouteProps {
  children: ReactNode;
}

const AuthRoute: React.FunctionComponent<IAuthRouteProps> = (props) => {
  const { children } = props;

  // auth,currentuser provides the firebase.User object if authenticated.
  if (!auth.currentUser) {
    return <Navigate to="/" />;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthRoute;

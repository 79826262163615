import { ChakraProvider } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Loader from './components/Loader';
import { auth } from './config/firebase';
import AuthRoute from './features/auth/AuthRoute';
import {
  getCurrentUserDentist,
  getCustomersAndQuestions,
  getGuide,
  getLanguages,
  getProposals,
  getTemplates,
  resetProposalState,
  setFirebaseToken,
  setFirebaseUserid,
} from './features/proposals/proposalSlice';
import { ViewportProvider } from './mediaQueries/ViewportContext';

const LoginPage = lazy(() => import('./routes/LoginPage'));
const DashboardPage = lazy(() => import('./routes/DashboardPage'));
const ProposalsPage = lazy(() => import('./routes/ProposalsPage'));
const SettingsPage = lazy(() => import('./routes/SettingsPage'));
const StatisticsPage = lazy(() => import('./routes/StatisticsPage'));

const theme = extendTheme({
  colors: {
    freshblue: '#1D32B0',
    brand: {
      100: '#1D32B0',
      200: '#1D32B0',
      300: '#1D32B0',
      400: '#1D32B0',
      500: '#1D32B0',
      600: '#1D32B0',
      700: '#1D32B0',
      800: '#1D32B0',
      900: '#1D32B0',
    },
  },
});

function App() {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  // Monitor and Update user state.
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(setFirebaseUserid(user.uid));
        user.getIdToken(true).then((idToken) => {
          dispatch(setFirebaseToken(idToken));
          dispatch(getCurrentUserDentist());
          dispatch(getLanguages());
          dispatch(getCustomersAndQuestions());
          dispatch(getTemplates());
          dispatch(getProposals());
          dispatch(getGuide());
        });
      } else {
        dispatch(resetProposalState());
      }
      setLoading(false);
    });
  }, [dispatch]);

  if (loading) return <div>Loading...</div>;

  return (
    <ViewportProvider>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <Routes>
            <Route
              path="/"
              element={
                <Suspense fallback={<Loader />}>
                  <LoginPage />
                </Suspense>
              }
            />
            <Route
              path="/dashboard"
              element={
                <Suspense fallback={<Loader />}>
                  <AuthRoute>
                    <DashboardPage />
                  </AuthRoute>
                </Suspense>
              }>
              {/* <Route index={true} element={<AuthRoute><DashboardPage /></AuthRoute>} /> */}
              <Route
                index
                element={
                  <Suspense fallback={<Loader />}>
                    <ProposalsPage />
                  </Suspense>
                }
              />
              <Route
                path="statistics"
                element={
                  <Suspense fallback={<Loader />}>
                    <StatisticsPage />
                  </Suspense>
                }
              />
              <Route
                path="settings"
                element={
                  <Suspense fallback={<Loader />}>
                    <SettingsPage />
                  </Suspense>
                }
              />
              <Route
                path="questionnaire"
                element={
                  <Suspense fallback={<Loader />}>
                    <QuestionnairePage />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </ChakraProvider>
      </BrowserRouter>
      <ToastContainer />
    </ViewportProvider>
  );
}

export default App;

function QuestionnairePage() {
  return <h1>Questionnaire page</h1>;
}
